import { styled } from '@pulse-web-ui/theme';

//TODO: доработать для отдельно открываемого браузера в мобильном приложении

export const AppWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme?.colors?.surface.background};
`;
