import { PublicRoutes } from '@shared/routes';
import { useEffect } from 'react';

import { refreshToken } from '@shared/utils';
import { useStores } from '@shared/hooks';
import { useLocation } from 'react-router-dom';

export const useHandleSessionRecovery = () => {
  const {
    MainStore: {
      authStore: { authTokens, setAuthTokens },
      initProductStore: { isInitSessionRecovery, setIsInitSessionRecovery },
      productStore: { agentLogin },
    },
  } = useStores();

  const { pathname } = useLocation();

  useEffect(() => {
    const csrfToken = localStorage.getItem('csrfToken');

    if (
      isInitSessionRecovery &&
      csrfToken &&
      !authTokens?.authorization?.csrfToken &&
      !agentLogin &&
      pathname !== PublicRoutes.PAYMENT_ERROR_ROUTE &&
      pathname !== PublicRoutes.PAYMENT_SUCCESS_ROUTE &&
      pathname !== PublicRoutes.PAYMENT_IN_PROGRESS_ROUTE
    ) {
      refreshToken()
        .then((e) => {
          const {
            data: {
              authorization: { csrfToken },
            },
          } = e;
          setAuthTokens(e?.data);
          localStorage.removeItem('csrfToken');

          if (csrfToken) {
            localStorage.setItem('csrfToken', csrfToken);
          }
        })
        .catch((error) => {
          const { response } = error;

          if (response?.status === 401) {
            localStorage.removeItem('csrfToken');
          }

          return null;
        })
        .finally(() => {
          setIsInitSessionRecovery(false);
        });
    }
  }, [agentLogin]);

  return null;
};
